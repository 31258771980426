import type * as React from 'react';
import cx from 'classnames';
import { useBrandRefresh } from '../../hooks/useBrandRefresh';

export type IProps = {
  resortFeeText: string;
};

const ResortFeeDetails: React.FC<React.PropsWithChildren<IProps>> = ({ resortFeeText }) => {
  const { isBrandRefresh } = useBrandRefresh();
  return resortFeeText ? (
    <div className="resort-fee-desc bg-light-blue mb-6 mt-4 px-6 py-4" data-testid="fee-wrapper">
      <p
        className={cx('leading-normal', {
          'text-lg font-semibold': !isBrandRefresh,
        })}
      >
        {resortFeeText}
      </p>
    </div>
  ) : null;
};

export default ResortFeeDetails;
