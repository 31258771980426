import {
  ENTERPRISE_THEME_REFRESH_TOGGLE_NAME,
  getBrandRefreshProps,
} from '@dx-ui/osc-styles/utils/theme-toggles';
import { useGetFeatureTogglesQuery } from '../generated/react-query';

type BrandRefreshData = ReturnType<typeof getBrandRefreshProps>;

export function useBrandRefresh() {
  const { data } = useGetFeatureTogglesQuery({
    names: [ENTERPRISE_THEME_REFRESH_TOGGLE_NAME],
  });
  return data?.featureToggles
    ? getBrandRefreshProps({ featureToggles: data?.featureToggles })
    : ({} as BrandRefreshData);
}
